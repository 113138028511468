import { DwollaCustomerType } from '../common/enums/dwolla-customer-type';

export default function () {
  const { data } = useAuth();
  const $auth = data.value;

  if ($auth?.type === DwollaCustomerType.ReceiveOnly) {
    return true;
  }

  return $auth?.paymentsAllowed || $auth?.fundingSourceForFees;
}
